<template>
  <b-container fluid style=" background: var(--light); height: 100vh">
    <div class="d-none d-md-block">
      <NavBar :sideMenuExpanded="isSideMenuExpanded" />
    </div>
    <div class="d-block d-md-none">
      <MobileSideBar :sideMenuExpanded="isSideMenuExpanded" />
    </div>
    <TopBar />
   
    <b-container
    class="d-flex flex-column align-items-start justify-content-start px-0 mx-0 py-3 root-layout"
    
      
    >
      <slot>
        
      </slot>
      <!-- <Footer /> -->
    </b-container>
    <!-- <Loader v-if="isLoading" /> -->
    <!-- <ToastNotification /> -->
  </b-container>
</template> 

<script>
  // store
  import { mapGetters } from "vuex";

  // components
  import TopBar from "@/components/common/TopBar";
  import NavBar from "@/components/common/NavBar";
  import MobileSideBar from "@/components/common/MobileSideBar";
  // import Footer from "@/components/common/Footer";
  // import Loader from "@/components/common/Loader";
  //import ToastNotification from "@/components/common/ToastNotification";

  export default {
    name: "HomeLayout",
    components: {
      TopBar,
      NavBar,
      MobileSideBar,
      // Footer,
      // Loader,
      //ToastNotification,
    },
    data() {
      return {
        showSidebar: false,
      };
    },
    computed: {
      ...mapGetters({
        isSideMenuExpanded: "general/getIsSideMenuExpanded",
        isLoading: "getIsLoading",
      }),
    },
  };
</script>
<style scoped>
  .root-layout {
    position: relative;
  background: var(--light);
  width: calc(100% - 81px);
  max-width: calc(100% - 81px);
  top: 100px;
  left: 81px;
  right: 0;
  transition: max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  }
  /* collapsed state design */
  .root-layout.collapsed {
    left: 81px;
    width: calc(100% - 81px);
    max-width: calc(100% - 81px);
  }
  /* Small devices (landscape phones, less than 768px) */
  @media (max-width: 767.98px) {
    .root-layout,
    .root-layout.collapsed {
      left: 0;
      width: 100%;
      max-width: 100vw;
      min-width: 100%;
    }
  }
</style>