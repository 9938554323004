<template>
    <b-sidebar
      class="nav-sidebar-outer d-print-none"
      sidebar-class="nav-sidebar"
      header-class="px-2 py-3 w-100 nav-sidebar-header"
      body-class="d-flex flex-column align-items-center justify-content-center"
      :visible="sideMenuExpanded"
      left
      width="100px"
      backdrop-variant="light"
      backdrop
      no-header-close
      bg-variant = white
      text-variant="dark"
      @hidden="closeSideBar"
    >
       <!-- default title header -->
    <template v-slot:title="{  }">
      <b-row
        class="d-flex flex-row align-items-center justify-content-between px-0 w-100"
        no-gutters
      >
        <!-- title logo -->
        <b-navbar-brand class="px-0 mx-0 my-1" to="/">
          <div class="logo-box">
      
      <b-img
        src="@/assets/TopbarLogo.png"
        class="img-logo px-0"
        height="20px"
        alt="Figa"
      ></b-img>
    </div>
        </b-navbar-brand>
       
      </b-row>
    </template>
      <!-- sidebar default content -->
      <template v-slot:default>
        <b-col
          class="d-flex flex-column justify-content-start align-items-start nav-container px-0 ml-4"
          is-nav
        >
          <b-navbar-nav
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <template>
              <!-- for testing purpose  -->
              <!-- the exact is used to make sure the roue / is exactly matched -->
              <b-nav-item
            to="/dashboard"
            exact
            title="Customers & Business Management"
            class="side-nav-item my-2"
            link-classes="p-0"
            active-class="active-link"
            @click.prevent
          >
            <b-row
              class="d-flex flex-row align-items-center justify-content-center side-nav-item-row px-3 py-3"
              no-gutters
            >
              <b-icon
                icon="building"
                class="d-flex jusify-content-center align-items-center side-nav-icon"
              >
              </b-icon>
            </b-row>
          </b-nav-item>
          <b-nav-item
            to="/coupons"
            title="Coupons"
            exact
            class="side-nav-item my-2"
            link-classes="p-0"
            active-class="active-link"
            @click.prevent
          >
            <b-row
              class="d-flex flex-row align-items-center justify-content-center side-nav-item-row px-3 py-3"
              no-gutters
            >
              <b-icon
                icon="lock-fill"
                class="d-flex jusify-content-center align-items-center side-nav-icon"
              />
            </b-row>
          </b-nav-item>
          <b-nav-item
            to="/administrators"
            title="Customer details, invoice records, outstanding amounts and reminders."
            exact
            class="side-nav-item my-2"
            link-classes="p-0"
            active-class="active-link"
            @click.prevent
          >
            <b-row
              class="d-flex flex-row align-items-center justify-content-center side-nav-item-row px-3 py-3"
              no-gutters
            >
              <b-icon
                icon="people"
                class="d-flex jusify-content-center align-items-center side-nav-icon"
              />
            </b-row>
          </b-nav-item>
          <b-nav-item
            to="/account"
            title="Inventory items and description with amounts used on invoices."
            exact
            class="side-nav-item my-2"
            link-classes="p-0"
            active-class="active-link"
            @click.prevent
          >
            <b-row
              class="d-flex flex-row align-items-center justify-content-center side-nav-item-row px-3 py-3"
              no-gutters
            >
              <b-icon
                icon="gear-fill"
                class="d-flex jusify-content-center align-items-center side-nav-icon"
              />
            </b-row>
          </b-nav-item>
            
             
            
            
             
             
            </template>
          </b-navbar-nav>
          <b-navbar-nav
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <template v-for="(item, index) in menuArrayBottom">
              <!-- :active="sideMenuIndex == index + 1 + menuArrayTop.length ? true : false" -->
              <b-nav-item
                :key="key + index"
                :to="item.route"
                @click="routeFunc(item, index + 1 + menuArrayTop.length)"
                class="side-nav-item my-2"
                link-classes="p-0"
                active-class="active-link"
                @click.prevent
              >
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start side-nav-item-row px-3 py-2"
                  no-gutters
                >
                  <b-icon
                    font-scale="2"
                    :icon="item.icon"
                    class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
                  ></b-icon>
                  <span
                    class="side-nav-text font-primary font-weight-normal text-left"
                    >{{ item.label }}</span
                  >
                </b-row>
              </b-nav-item>
            </template>
          </b-navbar-nav>
        </b-col>
      </template>
    </b-sidebar>
  </template>
  <script>
  import { mapGetters } from "vuex";
  export default {
    name: "MobileSideBar",
    props: {
      sideMenuExpanded: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      return {
        key: Number(new Date()),
        menuArrayTop: [],
        menuArrayBottom: [],
        isMultiple: false,
      };
    },
    computed: {
      ...mapGetters({
        sideMenuIndex: "getSideMenuIndex",
      }),
    },
    created() {
      this.menuArrayTop = [
      
      ];
      
      this.menuArrayBottom = [
      
      ];
      // if (localStorage.getItem("masterAdmin")) {
      //   let admin = JSON.parse(localStorage.getItem("masterAdmin"));
      //   this.adminType = admin.adminType;
      // }
    },
    methods: {
      closeSideBar() {
        this.$store.dispatch("general/setIsSideMenuExpanded", false);
        // this.$emit("onClose");
      },
      logout() {
        // this.$store.dispatch("setSideMenuIndex", 0);
        localStorage.clear();
        this.$store.dispatch("setIsLoading", false);
        this.$router.push("/login").catch((err) => {
          console.log("LOGOUT ERROR: ", err);
        });
        // location.reload();
      },
      routeFunc(item) {
        // console.log(item.route, this.$route.path);
        // console.log(item);
        this.key = Number(new Date());
        this.$store.dispatch("setIsLoading", false);
        // this.$store.dispatch("setSideMenuIndexKey", this.key);
        // this.$store.dispatch("setSideMenuIndex", index);
        this.$store.dispatch("setIsSideMenuExpanded", false);
  
        if (item.label === "Logout") {
          this.logout();
        } else {
          this.$router.push(item.route).catch((err) => {
            console.log("ROUTE SWITCH ERROR: ", err);
          });
        }
      },
    },
  };
  </script>
  <style scoped>
  .nav-sidebar-outer .nav-container {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    
  }
  .nav-sidebar-outer .logo-img {
    max-height: 60px;
  }
  .nav-sidebar-outer >>> .nav-sidebar-header {
    font-size: 1rem;
  }
  .nav-sidebar-outer >>> .nav-sidebar-header strong {
    width: 100%;
    font-weight: normal;
    font-size: 1rem;
  }
  /* scrollbar size fix for webkit browsers (chrome/safari) */
  .nav-sidebar-outer .nav-container::-webkit-scrollbar {
    width: 0.5rem;
    scrollbar-width: thin;
    background-color: var(--dark);
  }
  .nav-sidebar-outer .nav-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--primary);
  }
  .nav-sidebar-outer .nav-container::-webkit-scrollbar-thumb {
    background-color: var(--light);
  }
  /* side nav design */
  .navbar .navbar-nav {
    width: 90%;
  }
  /* side nav design */
  .navbar .navbar-nav .side-nav-item {
    width: 100%;
  }
  /* side nav logo box */
  .logo-box {
    /* box-sizing: border-box; */
    position: relative;
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background: rgba(255, 255, 255, 0.25); */
    /* background: rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(12.5px);
    border-radius: 5px; */
  }
  /* nav link (icon/text) color (default state)  */
  .side-nav-item a.nav-link .side-nav-item-row {
    width: 100%;
    border-radius: 0.25rem;
    background-color: transparent;
    padding: 0.8rem 1.25rem;
    transition: background-color 100ms linear 0s, color 100ms linear 0s;
  }
  .side-nav-item a.nav-link .side-nav-item-row .side-nav-icon {
    color: black;
    transition: color 100ms ease-in;
    user-select: none;
    height: 26px;
    width: 26px;
  }
  .side-nav-item a.nav-link .side-nav-item-row .side-nav-text {
    font-size: 1rem;
    color: black;
    padding-left: 0.65rem;
    transition: color 100ms ease-in, opacity 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
    opacity: 1;
    user-select: none;
  }
  /* nav link (icon/text) hover color (default state) */
  .side-nav-item a.nav-link:hover .side-nav-item-row {
    background-color: var(--light);
  }
  .side-nav-item a.nav-link:hover .side-nav-item-row .side-nav-icon,
  .side-nav-item a.nav-link:hover .side-nav-item-row .side-nav-text {
    color: black;
  }
  /* nav link (icon/text) color (active state)  */
  .side-nav-item a.nav-link.active-link .side-nav-item-row {
    cursor: auto;
    background-color: var(--light);
  }
  .side-nav-item a.nav-link.active-link .side-nav-item-row .side-nav-icon {
    color: black;
  }
  .side-nav-item a.nav-link.active-link .side-nav-item-row .side-nav-text {
    color: black;
  }
  /* sub item styling */
  .side-nav-item .side-nav-subitem {
    width: 100%;
    max-width: 80%;
    margin-left: 20%;
    color: var(--white);
  }
  /* nav sub item default state */
  .side-nav-item .side-nav-subitem .side-nav-text {
    color: black;
  }
  
  /* nav sub item hover state */
  .side-nav-item .side-nav-subitem .side-nav-text:hover {
    color: black;
  }
  .side-nav-item a.nav-link.active-link .side-nav-subitem .side-nav-text {
    color: black;
  }
  </style>