<template>
  <b-navbar
    type="light"
    class="d-flex flex-column align-items-center justify-content-between px-0 pt-0 pb-0 main-navbar"
  >
    <b-col
      class="d-flex flex-column justify-content-between align-items-center px-0 w-100"
      is-nav
    >
      <b-navbar-nav
        class="d-flex flex-column align-items-center justify-content-start mt-5"
      >
        <template>
          <!-- for testing purpose  -->
          <!-- the exact is used to make sure the roue / is exactly matched -->
          <b-nav-item
            to="/dashboard"
            exact
            title="Customers & Business Management"
            class="side-nav-item my-2"
            link-classes="p-0"
            active-class="active-link"
            @click.prevent
          >
            <b-row
              class="d-flex flex-row align-items-center justify-content-center side-nav-item-row px-3 py-3"
              no-gutters
            >
              <b-icon
                icon="building"
                class="d-flex jusify-content-center align-items-center side-nav-icon"
              >
              </b-icon>
            </b-row>
          </b-nav-item>
          <b-nav-item
            to="/coupons"
            title="Coupons"
            exact
            class="side-nav-item my-2"
            link-classes="p-0"
            active-class="active-link"
            @click.prevent
          >
            <b-row
              class="d-flex flex-row align-items-center justify-content-center side-nav-item-row px-3 py-3"
              no-gutters
            >
              <b-icon
                icon="lock-fill"
                class="d-flex jusify-content-center align-items-center side-nav-icon"
              />
            </b-row>
          </b-nav-item>
          <b-nav-item
            to="/administrators"
            title="Customer details, invoice records, outstanding amounts and reminders."
            exact
            class="side-nav-item my-2"
            link-classes="p-0"
            active-class="active-link"
            @click.prevent
          >
            <b-row
              class="d-flex flex-row align-items-center justify-content-center side-nav-item-row px-3 py-3"
              no-gutters
            >
              <b-icon
                icon="people"
                class="d-flex jusify-content-center align-items-center side-nav-icon"
              />
            </b-row>
          </b-nav-item>
          <b-nav-item
            to="/account"
            title="Inventory items and description with amounts used on invoices."
            exact
            class="side-nav-item my-2"
            link-classes="p-0"
            active-class="active-link"
            @click.prevent
          >
            <b-row
              class="d-flex flex-row align-items-center justify-content-center side-nav-item-row px-3 py-3"
              no-gutters
            >
              <b-icon
                icon="gear-fill"
                class="d-flex jusify-content-center align-items-center side-nav-icon"
              />
            </b-row>
          </b-nav-item>
        </template>
      </b-navbar-nav>
    </b-col>
  </b-navbar>
</template>
<script>
// import { mapGetters } from "vuex";
export default {
  name: "NavBar",
  data() {
    return {

    };
  },
  created() {
    this.menuArrayTop = [
      {
        icon: "building",
        label: "Customers & Business Management",
        route: "/dashboard",
      },
      {
        icon: "lock-fill",
        label: "Coupons",
        route: "/coupons",
      },
      {
        icon: "people",
        label: "Administrators",
        route: "/administrators",
      },
      {
        icon: "gear-fill",
        label: "Account Settings",
        route: "/account",
      }
    ];
  },
  methods: {
    
  },
};
</script>
<style scoped>
.main-navbar {
  /* background-color: var(--primary); */
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 80px;
  max-width: 80px;
  height: calc(100% - 60px);
  /* overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  min-height: 100vh; */
  /* min-height: calc(100vh - 140px); */
  /* z-index: 200; */
  bottom: 0;
  left: 0;
  transition: max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.main-navbar::-webkit-scrollbar {
  width: 0.5rem;
  scrollbar-width: thin;
}
.main-navbar::-webkit-scrollbar-track {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
}
.main-navbar::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
}
/* side nav design */
.navbar .navbar-nav {
  width: 100%;
}
/* side nav design */
.navbar .navbar-nav .side-nav-item {
  width: 100%;
}
/* nav link (icon/text) color (default state)  */
.side-nav-item a.nav-link .side-nav-item-row {
  width: 100%;
  border-right: 1px solid transparent; 
  border-width: 3px;
  /*transition: background-color 100ms linear 0s, color 100ms linear 0s; */
}
.side-nav-item a.nav-link .side-nav-item-row .side-nav-icon {
  /* color: var(--black); */
  color: var(--gray-750);
  /* transition: color 100ms ease-in; */
  user-select: none;
  height: 30px;
  width: 30px;
}
/* nav link (icon/text) hover color (default state) */
.side-nav-item a.nav-link:hover .side-nav-item-row {
  /* border-right: 1px solid #0B9B7D; 
  border-width: 3px; */
}
.side-nav-item a.nav-link:hover .side-nav-item-row .side-nav-icon{
  color: #0B9B7D;
}
/* nav link (icon/text) color (active state)  */
.side-nav-item a.nav-link.active-link .side-nav-item-row {
  cursor: auto;
  border-right: 1px solid #0B9B7D; 
  border-width: 3px;
}
.side-nav-item a.nav-link.active-link .side-nav-item-row .side-nav-icon {
  color: #0B9B7D;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .main-navbar.collapsed {
    max-width: 60px;
  }
}
</style>
